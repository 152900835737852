<template>
    <div>
        <div class="title">
            <h2>{{ $t('menu.locais') }}</h2>
        </div>

        <HoraLocal :title="' '" />

        <Loading :active="loading" />
        <div class="relative flex">
            <input class="py-1.5 px-2.5 border border-solid border-cinza block w-full rounded" type="text" v-model="search" :placeholder="$t('locais.pesquisar')" />
            <div class="py-1.5 px-4 absolute right-0 top-0 cursor-pointer" @click.prevent="search = ''">
                <i class="fa fa-trash" />
            </div>
        </div>
        <div v-for="(l, k) in locals" :key="k">
            <div class="container my-3.5 mx-0 border border-solid border-cinza rounded" v-if="!loading">
                <div>
                    <div class="content flex p-2 cursor-pointer" @click="l.open = !l.open" :class="{ active: l.open }">
                        <div class="left">
                            <div class="gmt text-azul text-s">{{ l.timezone }}</div>
                            <div class="flex flex-wrap">
                                <img v-for="(p, kp) in l.paises" :key="kp" class="mr-1 h-6" :src="p.img" />
                            </div>
                        </div>
                        <div class="hora flex-2">
                            <div class="hora-content flex">
                                <div class="hora-date flex items-center justify-center flex-col text-azul font-bold text-base flex-2">
                                    <div class="date text-sm mb-1">{{ l.dateFormat }}</div>
                                    <div class="time text-xl text-white bg-roxo rounded-10">{{ l.dateHours }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="down flex items-center"><i class="fa fa-angle-down"></i></div>
                    </div>
                </div>
                <collapse-transition>
                    <div class="open" :class="l.open ? 'open-up' : 'open-down'" v-show="l.open">
                        <div v-for="(p, kp) in l.paises" :key="kp" class="open-content flex flex-col pt-2">
                            <div class="text-gray-800 font-bold text-sm uppercase mb-2 flex justify-center items-center">
                                <img class="mr-1 h-8" :src="p.img" />
                                <div>{{ p.pais }}</div>
                            </div>
                            <div>
                                <div v-for="(c, ck) in p.location" :key="ck">
                                    <div class="coord-content flex justify-around mb-2">
                                        <div class="flex-2">
                                            <div class="text-azul text-xs text-center font-bold">{{ c.city }}</div>
                                            <div class="text-azul text-sm">
                                                {{ c.coord }}
                                            </div>
                                            <div class="options">
                                                <div class="recomendado" v-if="c.recomendado">Recomendado</div>
                                                <div class="novo" v-if="c.new">Novo</div>
                                            </div>
                                        </div>
                                        <div class="flex-1">
                                            <vue-flip active-click width="100px" height="45px" class="my-auto">
                                                <template v-slot:front>
                                                    <div class="flex h-12 w-full items-center justify-center" @click="getCopy(c.coord)">
                                                        {{ $t('locais.copiar') }}
                                                    </div>
                                                </template>
                                                <template v-slot:back>
                                                    <div class="flex h-12 w-full items-center justify-center" @click="getCopy(c.coord)">=)</div>
                                                </template>
                                            </vue-flip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import 'moment-timezone'
import 'moment-countdown'
import countdown from 'countdown'
import VueFlip from 'vue-flip'
import Loading from '../components/Loading'
import '@fortawesome/fontawesome-free/css/all.css'
import HoraLocal from '../components/HoraLocal'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import { getAnalytics, logEvent } from 'firebase/analytics'

countdown.setLabels(
    ' milissegundo|s|m|h|d| semana| mês| ano| década| século| milênio',
    ' milissegundos|s|m|h|d| semanas| meses| anos| décadas| séculos| milênios',
    ' ',
    ' ',
    'agora',
    e => String(e).padStart(2, '0')
)

export default {
    components: { VueFlip, Loading, HoraLocal, CollapseTransition },
    data: () => ({
        local: [],
        loading: false,
        search: '',
        localData: []
    }),
    computed: {
        locals() {
            if (this.search) {
                return this.local.filter(l => {
                    let r = l.paises.findIndex(p => {
                        return p.filtro
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(
                                this.search
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                            )
                    })
                    return l.paises[r]
                })
            }
            return this.local
        }
    },
    methods: {
        nextDay(w) {
            let today = new Date()
            let todayN = today.getDay()
            let offset = (todayN < w ? w : 10) - todayN
            today.setDate(today.getDate() + offset)
            return today
        },
        getHoras() {
            this.local.map(i => {
                i.date = moment()
                    .tz(i.paises[0].zone)
                    .format('HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY')
                i.dateFormat = moment()
                    .tz(i.paises[0].zone)
                    .format('ddd, DD MMM YY')
                i.dateHours = moment()
                    .tz(i.paises[0].zone)
                    .format('HH:mm:ss')
                i.count = moment(
                    moment()
                        .tz(i.paises[0].zone)
                        .format('YYYY-MM-DD HH:mm:ss')
                )
                    .countdown(
                        moment(this.nextDay(9))
                            .set({
                                hour: 18,
                                minute: 0,
                                second: 0
                            })
                            .format('YYYY-MM-DD HH:mm')
                    )
                    .toString()
                return i
            })
            this.loading = false
        },
        getCopy(coord) {
            this.$copyText(coord).then(
                () => null,
                () => alert('Erro')
            )
        },
        async getLocal() {
            let localData = await axios(`${this.$url}/lugares`)
            if (localData.data.length) {
                this.localData = localData.data.map(l => {
                    l.date = null
                    l.dateFormat = null
                    l.dateHours = null
                    l.dateNew = null
                    l.open = false
                    l.count = null
                    l.d = moment().tz(l.paises[0].zone)
                    l.paises.map(p => {
                        p.filtro = [p.pais].concat(p.city).toString()
                        return {
                            zone: p.zone,
                            pais: p.pais,
                            img: p.img,
                            filtro: p.filtro
                        }
                    })
                    return l
                })
                this.local = this.localData.map(i => {
                    i.open = false
                    return i
                })

            }
        }
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Locais'
        })
        moment.locale(this.$i18n.locale)
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.getLocal()
        this.loading = true
        setInterval(this.getHoras, 1000)
    }
}
</script>

<style lang="scss" scoped>
.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}
.container {
    .down {
        i.active {
            color: #fff;
        }
    }
    .content {
        .left {
            flex: 0.8;
        }
        .hora {
            .time {
                padding: 2px 5px;
            }
        }
    }
    .active {
        background-color: #673ab7;
        border-radius: 5px 5px 0 0;
        .down {
            i {
                color: #fff;
            }
        }
        .left {
            .gmt {
                color: #fff;
            }
        }
        .hora {
            .hora-date {
                color: #fff;
            }
        }
    }
}

.options {
    display: flex;
    .recomendado {
        font-size: 10px;
        border-radius: 10px;
        padding: 1px 6px;
        background-color: #2664fb;
        color: #fff;
        margin: 0 5px;
    }
    .novo {
        font-size: 10px;
        border-radius: 10px;
        padding: 1px 6px;
        background-color: #f44336;
        color: #fff;
        margin: 0 5px;
    }
}
</style>

<style lang="scss">
.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

*,
:after,
:before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

button,
input {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

button,
input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
</style>
